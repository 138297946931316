<template>
<div class="web-chat">
	<div class="nav nav-tabs flex-nowrap mb-3">
		<a class="nav-link d-flex flex-nowrap flex-shrink-1 px-2 py-1" :class="{active: fltr == filter, 'overflow-hidden': fltr != ''}" @click.prevent="filter = fltr" v-for="(fltr,i) in filters" href="#">
			<a href="#" v-if="fltr" @click.prevent.stop="filter = ''; filters.splice(i, 1);"><i class="fas fa-times mr-2"></i></a>
			{{fltr == '' ? tr('General') : fltr.replace(/^.* /, '')}}
		</a>
		<a class="nav-link d-flex flex-shrink-1 px-2 py-1" :class="{active:filter == 'users'}" @click.prevent="filter = 'users'" href="#">{{tr('Participants')}}</a>
	</div>
	<div class="usr-list border rounded mb-3 p-3" v-if="filter == 'users'">
		<template v-for="(usr,i) in users">
		<div class="usr-item border-bottom rounded pb-2 mb-2" :key="usr.name" v-if="usr.name != user.name">
			<button class="btn btn-outline-secondary btn-xs float-right" @click="filters.indexOf(usr.name) == -1 ? filters.push(usr.name) : null; filter = usr.name;"><i class="fas fa-paper-plane"></i></button>
			<div class="font-weight-bold">{{usr.name}}</div>
			<div class="small">{{usr.job}}, {{usr.position}}</div>
		</div>
		</template>
	</div>
	<template v-else>
	<div class="msg-list border rounded mb-3 p-3">
		<template v-for="(msg,i) in msgs">
		<div class="msg-item border rounded mb-1 px-3 py-2" :class="{'ml-auto':i % 2,'bg-light':msg.user.name == user.name,'moderator':msg.user.moder}" :key="msg.key" v-if="(msg.filter == undefined || msg.filter == filter) && filter == '' || msg.filter == filter && msg.user.name == user.name || msg.user.name == filter && msg.filter == user.name">
			<a href="#" @click.prevent="delMsg(i)"><i class="fas fa-times float-right ml-2 mt-1" v-if="msg.user.name == user.name || user.moder"></i></a>
			<small>{{msg.user.name}}</small>
			<small class="float-right mt-1">{{msg.time.toString().indexOf(' ') != -1 ? msg.time.toString().replace(/^.+?\s/, '') : (new Date(msg.time)).toLocaleTimeString()}}</small><br>
			{{msg.msg}}
		</div>
		</template>
	</div>
	<form @submit.prevent="addMsg">
		<div class="input-group mb-3">
			<input class="form-control" v-model="msg" :placeholder="tr('Enter your message')">
			<div class="input-group-append">
				<button class="btn btn-outline-dark" type="submit"><i class="fas fa-paper-plane"></i></button>
			</div>
		</div>
	</form>
	</template>
</div>
</template>

<script>
import * as firebase from 'firebase/app';
import 'firebase/database';

export default {
	name: 'WebChat',
	inject: [
		'tr',
	],
	props: {
		chat: String,
		user: Object,
	},
	data: function () {
		return {
			filter: '',
			filters: [''],
			users: [],
			msg: '',
			msgs: [],
		};
	},
	created: function () {
		firebase.database().ref(this.chat + '/logins').on("child_added", this.addUser);
		this.init();
	},
	mounted: function () {
	},
	methods: {
		init: function () {
			firebase.database().ref(this.chat + '/chat').on("child_added", this.getMsg);
		},
		getMsg: function (data) {
// 			console.log(data);
			var val = data.val();
// 			console.log(val);
			if(val.filter == this.user.name) {
				if(this.filters.indexOf(val.user.name) == -1) {
					this.filters.push(val.user.name);
				}
				this.filter = val.user.name;
			}
			this.msgs.push(Object.assign(val,{key:data.key}));
			this.$nextTick(function () {
// 				this.$refs.msg[this.$refs.msg.length - 1].scrollIntoView();
// 				var e = document.querySelector('.web-chat .msg-list .msg-item:last-child');
				var e = document.querySelector('.web-chat .msg-list');
				if(e) {
					e.scrollTo(0, e.scrollHeight);
// 					e.scrollIntoView();
				}
			});
		},
		addMsg: function () {
			if(this.msg == '') return;
			var data = {
				user: this.user,
				msg: this.msg,
				time: (new Date()).toISOString(),
				filter: this.filter,
			};
			if(this.moder) {
				data.moder = true;
			}
			firebase.database().ref(this.chat + '/chat').push(data);
// 			this.msgs.push(data);
			this.msg = '';
		},
		addUser: function (data) {
			var val = data.val();
// 			console.log(val);
			for(var i = 0; i < this.users.length; i++)
				if(this.users[i].name == val.user.name)
					return;
			this.users.push(val.user);
		},
		delMsg: function (i) {
			firebase.database().ref(this.chat + '/chat').child(this.msgs[i].key).remove();
			this.msgs.splice(i, 1);
		},
	},
	watch: {
		chat: function (val) {
// 			console.log(val);
			if(val) {
				this.init();
			}
		},
		users: function () {
			this.$emit('count', this.users.length);
		},
	},
}
</script>

<style>
.web-chat .nav-tabs .nav-link {
	background-color: #f8f9fa;
	border-color: #dee2e6;
	margin-bottom: -1px;
	margin-right: 5px;
}
.web-chat .nav-tabs .nav-link.active {
	border-bottom-color: white;
}
.web-chat .msg-list,
.web-chat .usr-list {
	height: 500px;
	overflow: auto;
}
.web-chat .msg-list .msg-item {
	width: 90%
}
.web-chat .msg-list .msg-item.moderator {
	background-color: lightcoral;
}
.web-chat .btn-xs {
	padding: 0 .25rem;
	font-size: .875rem;
	line-height: 1.5;
	border-radius: .2rem;
}
</style>
