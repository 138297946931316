<template>
<div class="web-poll">
	<template v-if="user.moder">
	<h2><i class="fas fa-poll"></i> {{tr('Polls management')}}</h2>
	<div class="border rounded mb-3 p-3">
		<div v-for="(item,p) in polls">
			<a class="mr-2" href="#" @click.prevent="see(p)" :title="tr('On/off poll')"><i class="fas" :class="item.show ? 'fa-eye-slash' : 'fa-eye'"></i></a>
			<a class="mr-2 text-success" href="#" @click.prevent="edit(p)" :title="tr('Edit poll')"><i class="fas fa-edit"></i></a>
			<a class="mr-2 text-warning" href="#" @click.prevent="clean(p)" :title="tr('Clear results')"><i class="fas fa-recycle"></i></a>
			<a class="mr-2 text-danger" href="#" @click.prevent="del(p)" :title="tr('Delete poll')"><i class="fas fa-trash"></i></a>
			{{p}}
		</div>
		<button class="btn btn-outline-primary mt-3" type="button" @click="show = true">{{tr('Add poll')}}</button>
	</div>
	</template>
	<ModalDialog v-if="show" size="xl" @close="show = false">
		<template slot="header">{{tr('Poll creation')}}</template>
		<form id="poll-form" @submit.prevent="add">
			<div class="form-group d-flex">
				<input class="form-control" v-model="name" placeholder="Poll name">
				<!--select class="form-control ml-3 w-25" v-model="lang">
					<option v-for="(lang,code) in langs" :value="code">{{lang}}</option>
				</select-->
			</div>
			<div class="form-group">
			<textarea class="form-control" v-model="poll" rows="24" placeholder="+Question 1
Answer 1
Answer 2
Answer 3

Question 2
Answer 1
Answer 2

+ multiple choice" />
			</div>
			<!--template v-for="(block,i) in poll">
				<div class="form-group">
					<div class="input-group">
						<input class="form-control" v-model="block.q" :placeholder="'Question ' + (i + 1)">
						<div class="input-group-append">
							<button class="btn btn-outline-danger" type="button" v-if="poll.length > 1" @click="poll.splice(i, 1)" title="Remove question">-</button>
							<button class="btn btn-outline-success" type="button" @click="poll.splice(i + 1, 0, {q: '', a: [''], n: true, m: false})" title="Add question">+</button>
						</div>
					</div>
				</div>
				<div class="form-group pl-5" v-for="(answer,j) in block.a">
					<div class="input-group">
						<input class="form-control" v-model="block.a[j]" :placeholder="'Answer ' + (j + 1)">
						<div class="input-group-append">
							<button class="btn btn-outline-danger" type="button" v-if="block.a.length > 1" @click="block.a.splice(j, 1)" title="Remove answer">-</button>
							<button class="btn btn-outline-success" type="button" @click="block.a.splice(j + 1, 0, '')" title="Add answer">+</button>
						</div>
					</div>
				</div>
			</template-->
		</form>
		<button class="btn btn-outline-success" type="submit" form="poll-form" slot="footer"><i class="fas fa-paper-plane"></i> {{tr('Send')}}</button>
	</ModalDialog>
	<template v-for="(poll,p) in polls">
	<template v-if="poll.show">
	<form v-if="poll.res === null" @submit.prevent="send(p)">
		<h2><i class="fas fa-poll"></i> {{tr('Poll')}}</h2>
		<div class="border rounded mb-3 px-3 pb-3">
			<div class="mt-3" v-for="(item,i) in poll.data">
				<div class="text-primary mb-2" v-html="item.q" />
				<label class="ml-4" :class="{'w-100': answer.length > 40}" v-for="(answer,j) in item.a"><input class="mr-2" :type="item.m ? 'checkbox' : 'radio'" :name="p + i" v-model="item.r" :value="j + 1" :required="item.n && !item.r.length"><span v-html="answer" /></label>
				<div class="" v-if="item.a.length == 0"><input class="form-control" v-model="item.r"></div>
			</div>
			<button class="btn btn-outline-success mt-3" type="submit"><i class="fas fa-paper-plane"></i> {{tr('Send')}}</button>
		</div>
	</form>
	<div class="results" v-if="poll.res !== null || user.moder">
		<h2><i class="fas fa-poll"></i> {{tr('Poll results')}}</h2>
		<div v-for="(r,i) in poll.res">
			<div class="question mt-3 text-primary">{{poll.data[i].q}}</div>
			<div class="answer d-flex my-2" v-for="(a,j) in poll.data[i].a">
				<input v-if="user.moder" class="offline form-control flex-shrink-0 mr-2 text-center" type="number" min="0" v-model.number="poll.off[i][j]">
				<div class="graph bg-light border form-control-plaintext rounded text-nowrap px-2" :style="{width: (r[j] + poll.off[i][j]) * 100 / Math.max(...r.map((v, k) => v + poll.off[i][k])) + '%'}">
					<div class="float-right" v-if="r[j] || poll.off[i][j]">{{r[j] + poll.off[i][j]}}</div>
					{{a}}
				</div>
			</div>
		</div>
		<button v-if="user.moder" class="btn btn-outline-success mt-3" type="button" @click="off(p)"><i class="fas fa-paper-plane"></i> {{tr('Send')}}</button>
	</div>
	<!--template v-if="user.moder">
	<form @submit.prevent="off(p)">
		<h2><i class="fas fa-poll"></i> {{tr('Offline poll results')}}</h2>
		<div class="border rounded mb-3 px-3 pb-3">
			<div class="mt-3" v-for="(item,i) in poll.data">
				<div class="text-primary mb-2" v-html="item.q" />
				<label class="d-flex ml-4" v-for="(answer,j) in item.a">
					<input class="form-control mr-2 w-25" type="number" min="0" v-model.number="poll.off[i][j]">
					<span class="form-control-plaintext w-100" v-html="answer" />
				</label>
				<div class="" v-if="item.a.length == 0"><input class="form-control" v-model="item.r"></div>
			</div>
			<button class="btn btn-outline-success mt-3" type="submit"><i class="fas fa-paper-plane"></i> {{tr('Send')}}</button>
		</div>
	</form>
	</template-->
	</template>
	</template>
</div>
</template>

<script>
import * as firebase from 'firebase/app';
import 'firebase/database';
import ModalDialog from './ModalDialog.vue';

export default {
	name: 'WebPoll',
	components: {
		ModalDialog,
	},
	inject: [
		'lng',
		'tr',
	],
	props: {
		event: String,
		user: Object,
	},
	data: function () {
		return {
// 			lang: 'uk',
// 			langs: {
// 				en: 'Engish',
// 				ru: 'Русский',
// 				uk: 'Українська',
// 			},
			name: '',
			poll: '',
// 			poll: [
// 				{
// 					q: '', // question
// 					a: [''], // answers
// 					n: true, // needed
// 					m: false, // multiple choice
// 				}
// 			],
			polls: {},
			show: false,
		};
	},
	created: function () {
		firebase.database().ref(this.event + '/polls').on("child_added", this.load);
		firebase.database().ref(this.event + '/polls').on("child_changed", this.load);
	},
	computed: {
	},
	methods: {
		load: function (data) {
// 			console.log(data);
			let lang = this.lng();
			let val = data.val();
// 			console.log(val);
			data.show = val.s;
			data.off = val.o;
			if(!val.hasOwnProperty(lang)) return;
			val = val[lang].split("\n\n");
			for(let i = 0; i < val.length; i++) {
				let a = val[i].split("\n");
				let q = a.shift();
				val[i] = {
					q: q.replace(/^[\*\+]/, ''), // question
					a: a, // answers
					r: [], // reply
					n: q.search(/^[\*\+]/) != -1, // needed
					m: q.search(/^[\+]/) != -1, // multiple choice
				};
			}
			if(!data.off) {
				data.off = [];
				for(let i = 0; i < val.length; i++) {
					data.off[i] = [];
					for(let j = 0; j < val[i].a.length; j++)
						data.off[i].push(0);
				}
			}
// 			console.log(data.key);
// 			console.log(val);
// 			let i = this.polls.push({ name: data.key, data: val, show: data.show }) - 1;
			this.$set(this.polls, data.key, {data: val, res: null, off: data.off, show: data.show });
			firebase.database().ref(this.event + '/polls/' + data.key + '/r/' + this.user.name)
				.once('value')
				.then((data) => {
					if(data.val()) {
						firebase.database().ref(this.event + '/polls/' + data.ref.parent.parent.key + '/r').on("child_added", this.res);
					}
				});
		},
		res: function (data) {
			let key = data.ref.parent.parent.key;
// 			console.log(key);
			if(this.polls[key].res === null) {
				this.polls[key].res = [];
				for(let i = 0; i < this.polls[key].data.length; i++) {
					this.polls[key].res[i] = [];
					for(let j = 0; j < this.polls[key].data[i].a.length; j++)
						this.polls[key].res[i].push(0);
				}
			}
			let val = data.val();
// 			console.log(val);
			for(let i = 0; i < val.length; i++)
				if(Array.isArray(val[i]))
					for(let j = 0; j < val[i].length; j++)
						this.polls[key].res[i][val[i][j] - 1]++;
				else
					this.polls[key].res[i][val[i] - 1]++;
		},
		off: function (p) {
			firebase.database().ref(this.event + '/polls/' + p + '/o').set(this.polls[p].off);
		},
		add: function () {
			firebase.database().ref(this.event + '/polls/' + this.name + '/s').set(false);
			firebase.database().ref(this.event + '/polls/' + this.name + '/' + this.lng()).set(this.poll);
			this.show = false;
		},
		clean: function (p) {
			firebase.database().ref(this.event + '/polls/' + p + '/r').remove();
			firebase.database().ref(this.event + '/polls/' + p + '/o').remove();
			this.polls[p].res = null;
			this.polls[p].off = null;
		},
		del: function (p) {
			firebase.database().ref(this.event + '/polls/' + p + '/s').set(false);
			firebase.database().ref(this.event + '/polls/' + p).remove();
			this.$delete(this.polls, p);
		},
		edit: function (p) {
			this.name = p;
			this.poll = '';
			for(let i = 0; i < this.polls[p].data.length; i++) {
				this.poll += (this.polls[p].data[i].m ? '+' : '') + this.polls[p].data[i].q + '\n' + this.polls[p].data[i].a.join('\n') + '\n\n';
			}
			this.poll = this.poll.trim();
			this.show = true;
		},
		see: function (p) {
			firebase.database().ref(this.event + '/polls/' + p + '/s').set(!this.polls[p].show);
		},
		send: function (p) {
			let r = [];
			for(let i = 0; i < this.polls[p].data.length; i++) {
// 				r.push([this.polls[p].data[i].q, this.polls[p].data[i].r]);
				r.push(this.polls[p].data[i].r);
			}
			firebase.database().ref(this.event + '/polls/' + p + '/r/' + this.user.name).set(r);
// 			this.polls.splice(p, 1);
// 			this.polls[p].show = false;
		},
	},
}
</script>

<style>
.web-poll .results .answer .offline {
	width: 100px;
}
</style>
