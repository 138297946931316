<template> 
	<div class="email-input position-relative">
		<input class="form-control position-absolute" v-if="required" :value="value" tabindex="-1" required>
		<input class="form-control position-relative d-inline-block" :class="{error: error}" type="email" v-model="email" :required="required">
		<svg v-if="error" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" class="position-relative text-danger svg-inline--fa fa-exclamation-triangle fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><title>{{ tr('Wrong e-mail') }}</title><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>
		<!--i class="fas fa-exclamation-triangle position-relative text-danger" v-if="error" :title="tr('Wrong e-mail')" />
		<small class="text-danger" v-if="error">{{ tr('Wrong e-mail') }}</small-->
	</div>
</template> 

<script>
export default {
	name: 'EmailInput',
	inject: [
		'tr',
	],
	props: [
		'required',
		'value',
	],
	data: function () {
		return {
			email: '',
			error: false,
		}
	},
	watch: {
		value: function (val, old) {
			if(val)
				this.email = val;
		},
		email: function (val, old) { // https://ru.vuejs.org/v2/cookbook/form-validation.html#Использование-пользовательских-проверок
			this.error = !val.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
			this.$emit('input', this.error ? '' : this.email);
		},
	},
}
</script>

<style>
.email-input .fa-exclamation-triangle {
	margin-left: -30px;
	width: 18px;
}
</style>
