<template>
<div class="web-conf" :class="lang">
	<div class="alert alert-danger mt-3" v-if="error"><button type="button" class="close ml-3" @click="error = null">×</button><i class="fas fa-exclamation-triangle"></i> {{tr('Error')}}: {{error}}</div>
	<div class="d-flex justify-content-center my-3" v-show="!error">
		<template v-if="!noreg">
		<RequestForm v-if="!data" :date="date" :event="event" :indi="indi" :comp="comp">
			<slot></slot>
		</RequestForm>
		</template>
		<div class="flex-shrink-0" v-else-if="!data">
			<slot></slot>
		</div>
		<LoginForm :event="event" @input="data = $event" _count="count = $event" @error="error = $event"/>
	</div>
	<div class="row" v-if="data">
		<div class="col-sm-8 d-flex flex-column">
			<template v-if="data.video || data.pdf">
			<h2><i class="far fa-folder-open"></i> {{tr('Downloads')}}</h2>
			<h3><a :href="data.video" v-if="data.video"><i class="fas fa-film"></i> {{tr('Video')}}</a></h3>
			<h3><a :href="data.pdf" v-if="data.pdf"><i class="far fa-file-pdf"></i> {{tr('PDF')}}</a></h3>
			</template>
			<template v-else>
			<h2><span class="badge badge-secondary float-right"><i class="far fa-clock"></i> {{(new Date(time)).toLocaleTimeString()}}</span><i class="fas fa-film"></i> {{tr('Online broadcast')}}</h2>
			<div class="stream-box border mb-3 w-100" :class="fscr ? 'full-screen' : 'position-relative'">
				<button class="btn btn-outline-secondary position-absolute d-none" style="right:10px;top:10px;" @click="fscr = !fscr"><i class="fas" :class="fscr ? 'fa-compress' : 'fa-expand'"></i></button>
				<iframe class="w-100 h-100" data-width="560" data-height="315" :src="data.stream[stream].link" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
			<div class="stream-name form-check form-check-inline justify-content-center mb-3 mx-0" v-if="data.stream.length > 1">
				<label class="form-check-label mr-3 _text-uppercase" v-for="(item, i) in data.stream" :key="i">
					<input class="form-check-input" type="radio" :value="i" v-model="stream">
					{{item.language}}
				</label>
			</div>
			</template>
			<WebPoll :event="event" :user="data.user"/>
		</div>
		<div class="col-sm-4 d-flex flex-column">
			<template v-if="!data.video && !data.pdf">
			<h2><span class="badge badge-secondary float-right"><i class="fas fa-users"></i> {{count}}</span><i class="far fa-comments"></i> {{tr('Chat')}}</h2>
			<WebChat :chat="event" :user="data.user" :moder="data.moder" @count="count = $event"/>
			</template>
		</div>
	</div>
	<CountDown :date="date"/>
</div>
</template>
 
<script>
import * as firebase from 'firebase/app';
import i18n from './i18n.vue';
import CountDown from './CountDown.vue';
import LoginForm from './LoginForm.vue';
import RequestForm from './RequestForm.vue';
import WebChat from './WebChat.vue';
import WebPoll from './WebPoll.vue';

export default {
	name: 'WebConf',
	components: {
		CountDown,
		LoginForm,
		RequestForm,
		WebChat,
		WebPoll,
	},
	props: {
		date: String,
		event: String,
		indi: Boolean,
		comp: Boolean,
		noreg: Boolean,
	},
	mixins: [
		i18n,
	],
	data: function () {
		return {
			error: null,
			count: 0,
			data: null,
			fscr: false,
			time: 0,
			stream: 0,
		};
	},
	provide: function () {
		return {
			post: this.post,
		}
	},
	created: function () {
		this.time = Date.now();
		setInterval(() => {this.time = Date.now();}, 1000);
		if(window.location.pathname.indexOf('/ru/') !== -1)
			this.lang = 'ru';
		if(window.location.pathname.indexOf('/en/') !== -1)
			this.lang = 'en';
		firebase.initializeApp({
			apiKey: "AIzaSyD-_oj6ET_h_l80SswBomCaIwdGe-xeQyU",
			authDomain: "conferences-edce9.firebaseapp.com",
			databaseURL: "https://conferences-edce9.firebaseio.com",
			projectId: "conferences-edce9",
			storageBucket: "conferences-edce9.appspot.com",
			messagingSenderId: "113231660476",
			appId: "1:113231660476:web:f0a35258b62931548797b9",
			measurementId: "G-36XQ8XGB0S"
		});
	},
	methods: {
		post: function (data) {
			data.language = this.lang;
// 			data.language = this.lang == 'uk' ? 'ua' : this.lang;
// 			console.log(data);
			return new Promise((resolve, reject) => {
				let xhr = new XMLHttpRequest();
				xhr.open('POST', 'https://axioma2.morion.ua');
// 				xhr.responseType = 'json';
				xhr.setRequestHeader('Content-Type', 'application/json');
				xhr.setRequestHeader('token', 'E22DCD31-25DF-4645-BE67-EEC4B7D19FC1');
				xhr.setRequestHeader('sign', '1');
				xhr.onreadystatechange = () => {
					if(xhr.readyState == 4 ) {
						if(xhr.status == 200) {
							resolve(JSON.parse(xhr.responseText));
						} else {
							reject(xhr.responseText);
						}
					}
				};
				xhr.send(JSON.stringify(data));
			});
		},
	},
	watch: {
		data: function () {
			if(!this.data) return;
			this.stream = 0;
			for(var i = 0; i < this.data.stream.length; i++) {
// 				if(this.data.stream[i].language == 'ua')
// 					this.data.stream[i].language = 'uk';
				if(this.data.stream[i].language == this.lang) {
					this.stream = i;
					break;
				}
			}
		},
	},
}
</script>

<style>
.full-screen {
	position: fixed!important;
	left: 0;
	top: 0;
	z-index: 10000;
}
.web-conf iframe {
	left: 0;
	position: absolute;
	top: 0;
}
.web-conf .stream-box {
	padding-top: 57%;
}
.web-conf .badge {
	font-size: 14px;
	margin-top: 10px;
}
.form-control[required] {
	border-color: #dc3545;
	border-width: 2px;
}
</style>
