<template>
<div class="request-form d-inline-block">
	<button class="register btn btn-outline-success mx-2" @click="show = true"><i class="far fa-edit"></i> {{tr('Participants registration')}}</button>
	<ModalDialog v-if="show" size="xl" @close="show = false">
		<template slot="header">{{tr('Registration')}}</template>
		<ul class="nav nav-tabs mb-2" v-if="indi === null">
			<li class="nav-item"><a class="nav-link" :class="{active: !indv}" @click.prevent="indv = false" href="#">{{tr('Collective application')}}</a></li>
			<li class="nav-item"><a class="nav-link" :class="{active: indv}" @click.prevent="indv = true" href="#">{{tr('Individual application')}}</a></li>
		</ul>
		<form id="request-form" class="row" @submit.prevent="sendData">
			<p class="col-12 text-danger" v-if="!indv">{{tr('All fields are required')}}</p>
			<div class="col-sm company" v-if="!indv">
				<h6><b>{{tr('Company information')}}:</b></h6>
				<div class="form-group promo">
					<label>{{tr('Promo code (if any)')}}</label>
					<input class="form-control" type="text" v-model="company.set_promocode">
				</div>
				<div class="form-group country">
					<label>{{tr('Country')}}</label>
					<input class="form-control" type="text" v-model="company.set_country" ref="input">
					<!--select class="form-control" v-model="company.set_country" ref="input">
						<option v-for="(country,code) in countries" :key="code" :value="code">{{tr(country)}}</option>
					</select-->
				</div>
				<div class="form-group name">
					<label>{{tr('Legal company name')}}</label>
					<input class="form-control" type="text" v-model="company.set_company_name" ref="input">
				</div>
				<div class="form-group tax">
					<label>{{tr('Tax code')}}</label>
					<input class="form-control" type="text" v-model="company.set_code_tax" ref="input">
				</div>
				<div class="form-group contact">
					<label>{{tr('Contact person')}}</label>
					<input class="form-control" type="text" v-model="company.set_contact_person" ref="input">
				</div>
				<div class="form-group position">
					<label>{{tr('Position')}}</label>
					<input class="form-control" type="text" v-model="company.set_contact_position">
				</div>
				<div class="form-group address">
					<label>{{tr('Post address')}}</label>
					<input class="form-control" type="text" v-model="company.set_address" ref="input">
				</div>
				<div class="form-group phone">
					<label>{{tr('Phone')}}</label>
					<input class="form-control" type="tel" pattern="\+[0-9\- ]{11,}" placeholder="+___ __ ___-__-__" v-model="company.set_phone" @focus="focus(company)" ref="input">
					<!--phone-input v-model="company.set_phone" :country="company.set_country" :required="true" /-->
				</div>
				<div class="form-group email">
					<label>E-mail</label>
					<email-input v-model="company.set_email" :required="true" />
				</div>
				<div class="form-group total">
					<label>{{tr('Total payment')}}</label>
					<input class="form-control" type="number" min="0" v-model="company.set_total" ref="input">
				</div>
			</div>
			<div class="col-sm participant">
				<h6 v-if="!indv"><b>{{tr('Please register as a participant')}}:</b></h6>
				<div class="my-3" :class="{'border-bottom border-danger': !indv}" v-for="(participant,i) in participants" :key="i">
					<p class="text-danger" v-if="!indv"><button class="btn btn-outline-danger float-right" type="button" @click="participants.splice(i, 1)"><i class="fas fa-user-times"></i> {{tr('Remove')}}</button>{{tr('Participant')}} №{{i + 1}}</p>
					<div class="form-group company">
						<label>{{tr('Legal company name')}}</label>
						<input class="form-control" type="text" v-model="company.set_company_name" ref="input">
					</div>
					<div class="form-group lastname">
						<label>{{tr('Last name')}}</label>
						<input class="form-control" type="text" v-model="participant.set_lastname" ref="input">
					</div>
					<div class="form-group firstname">
						<label>{{tr('First name')}}</label>
						<input class="form-control" type="text" v-model="participant.set_firstname" ref="input">
					</div>
					<div class="form-group middlename">
						<label>{{tr('Middle name')}}</label>
						<input class="form-control" type="text" v-model="participant.set_middlename" ref="input">
					</div>
					<div class="form-group position">
						<label :class="{'d-none':!indv}">{{tr('Medical specialty')}}</label>
						<label :class="{'d-none':indv}">{{tr('Position')}}</label>
						<input class="form-control" type="text" v-model="participant.set_position" ref="input">
					</div>
					<div class="form-group phone">
						<label>{{tr('Phone')}}</label>
						<input class="form-control" type="tel" pattern="\+[0-9\- ]{11,}" placeholder="+___ __ ___-__-__" v-model="participant.set_phone" @focus="focus(participant)" ref="input">
						<!--phone-input v-model="participant.set_phone" :country="company.set_country" :required="true" /-->
					</div>
					<div class="form-group email">
						<label>E-mail</label>
						<email-input v-model="participant.set_email" :required="true" />
					</div>
					<div class="form-group lang" v-if="!indv">
						<label>{{tr('Prefered language')}}</label>
						<select class="form-control" v-model="participant.set_pref_language" ref="input">
							<option v-for="(lang,code) in langs" :value="code" :key="code">{{lang}}</option>
						</select>
					</div>
					<div class="form-group format" v-if="formats.length > 1">
						<label>{{tr('Participation format')}}</label>
						<select class="form-control" v-model="participant.set_participant_format" ref="input">
							<option v-for="(format,i) in formats" :key="i">{{format.format}}</option>
						</select>
					</div>
				</div>
				<button class="btn btn-outline-success mt-3" type="button" v-if="!indv" @click="addPart"><i class="fas fa-user-plus"></i> {{tr('Add')}}</button>
			</div>
			<div class="col-12">
				<slot></slot>
			</div>
		</form>
		<template slot="footer">
			<div class="alert alert-danger flex-shrink-0 w-100" v-if="error"><button type="button" class="close ml-3" @click="error = null">×</button><i class="fas fa-exclamation-triangle"></i> {{tr('Error')}}: {{error}}</div>
			<button class="btn btn-outline-success" type="submit" form="request-form"><i class="fas fa-paper-plane"></i> {{tr('Send')}}</button>
		</template>
	</ModalDialog>
	<ModalDialog class="success" v-if="recd" size="md" @close="recd = false">
		<template slot="header">{{tr('Confirmation of registration')}}</template>
		<div>
			{{tr('Your application has been accepted and is being processed. Thank You for registering')}}<br/>
			{{tr('You will receive a special e-mail notification about your participation in the event shortly')}}
		</div>
	</ModalDialog>
</div>
</template>

<script>
// import PhoneInput from './PhoneInput.vue';
import EmailInput from './EmailInput.vue';
import ModalDialog from './ModalDialog.vue';

export default {
	name: 'RequestForm',
	components: {
// 		PhoneInput,
		EmailInput,
		ModalDialog,
	},
	props: {
		date: String,
		event: String,
		indi: Boolean,
	},
	inject: [
		'lng',
		'post',
		'tr',
	],
	data: function () {
		return {
			countries: {
				'by': 'Belarus',
				'en': 'International',
				'kz': 'Kazakhstan',
				'ru': 'Russia',
				'uz': 'Uzbekistan',
				'ua': 'Ukraine',
			},
			company: {
				set_request_id: Date.now().toString(),
				set_country: '',//'ua',
				set_company_name: '',
				set_code_tax: '',
				set_contact_person: '',
				set_contact_position: '',
				set_address: '',
				set_phone: '',
				set_email: '',
				set_promocode: '',
				set_total: '0',
			},
			error: null,
			formats: [],
			indv: false,
			langs: {
				uk: "Українська",
				ru: "Русский",
				en: "English",
			},
			participants: [],
			show: false,
			recd: false,
		};
	},
	created: function () {
		this.indv = this.indi;
		if(this.countries[this.lng()])
			this.company.set_country = this.lng();
		this.getForms();
	},
	methods: {
		focus(item) {
			if(item.set_phone == '') item.set_phone = '+';
		},
		addPart: function () {
			this.participants.push({
				set_request_id: Date.now().toString(),
				set_lastname: '',
				set_firstname: '',
				set_middlename: '',
				set_position: '',
				set_phone: '',
				set_email: '',
				set_participant_format: this.formats.length ? this.formats[0].format : '',
				set_pref_language: this.lng() == 'ua' ? 'uk' : this.lng(),
			});
		},
		getForms: function () {
			var data = {
				"query": {
					"object_id": this.event,
					"method": "conf_format",
				}
			};
			console.log(data);
			this.post(data).then((data) => {
				console.log(data);
				if(data.resultcode == 200) {
					this.formats = data.conf_format.result;
					this.addPart();
				} else {
					if('errormessage' in data) {
						this.error = data.errormessage;
					}
				}
			});
		},
		sendData: function () {
			if(this.indv) {
				this.company.set_contact_person = this.participants[0].set_lastname + ' ' + this.participants[0].set_firstname + ' ' + this.participants[0].set_middlename;
				this.company.set_contact_position = this.participants[0].set_position;
				this.company.set_address = this.company.set_code_tax;
				this.company.set_phone = this.participants[0].set_phone;
				this.company.set_email = this.participants[0].set_email;
				this.company.set_total = '0.01';
				this.participants[0].set_pref_language = this.lng() == 'ua' ? 'uk' : this.lng();
				if(this.participants[0].set_participant_format == '')
					this.participants[0].set_participant_format = 'Online';
			}
			for(const field in this.company)
				if(this.company[field] == '')
					this.company[field] = this.tr('Not filled');
			for(const participant in this.participants)
				for(const field in this.participants[participant])
					if(this.participants[participant][field] == '')
						this.participants[participant][field] = this.tr('Not filled');
			var data = {
				"change": {
					"object_id": this.company.set_request_id,
					"method": "registry_company",
					"dataset": [
						Object.assign({
							"set_conference_name": this.event,
							"set_conference_date": this.date,
							"include": [
								{
									"method": "registry_participant",
									"dataset": this.participants
								}
							]
						}, this.company),
// 						}, Object.assign(this.company, {set_country: this.countries[this.company.set_country]})),
					]
				}
			};
			console.log(data);
			this.post(data).then((data) => {
				console.log(data);
				if(data.resultcode == 200) {
					this.show = false;
					this.recd = true;
				} else {
					if('errormessage' in data) {
						this.error = data.errormessage;
					}
				}
			});
		},
	},
	watch: {
		indi: function (val) {
			this.indv = val;
		},
		show: function(val) {
			if(val)
				this.$nextTick(() => {
// 					console.log(this.$refs.input);
					this.$refs.input.forEach((item, index) => {
						this.$refs.input[index].required = item.parentNode.offsetWidth;
					});
				});
		},
	},
}
</script>
